<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <v-row
      class="mt-4 pr-6 pl-6"
    >
      <v-col
        cols="6"
      >
        <v-card class="mx-auto mt-0">
          <div
            ref="chart1"
            class="pa-4"
            style="width: 100%;height: 400px;"
          />
        </v-card>
      </v-col>
      <v-col
        cols="6"
      >
        <v-card class="mx-auto  mt-0">
          <div
            ref="chart2"
            class="pa-4"
            style="width: 100%;height: 400px;"
          />
        </v-card>
      </v-col>

      <v-col
        cols="6"
      >
        <v-card class="mx-auto  mt-0">
          <div
            ref="chart3"
            class="pa-4"
            style="width: 100%;height: 400px;"
          />
        </v-card>
      </v-col>
      <v-col
        cols="4"
      >
        <v-card class="mx-auto  mt-0">
          <div
            ref="chart4"
            class="pa-4"
            style="width: 100%;height: 400px;"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    data: () => ({
    }),
    computed: {
    },
    watch: {
    },
    async created () {
    },
    async mounted () {
      this.$nextTick(async () => {
        await this.drawPie1()
        await this.drawPie2()
        await this.drawPie3()
        await this.drawPie4()
      })
    },
    methods: {
      drawPie1 () {
        const ref = this.$refs.chart1
        var charts1 = this.$echarts.init(ref)
        var option
        option = {
          title: {
            text: '日收益',
            left: '0',
            top: '0',
            textStyle: {
              fontSize: 18,
              fontFamily: ' Microsoft YaHei, Microsoft YaHei-Bold',
              color: '#9C7EFC',
            },
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月'],

            axisLabel: {
              textStyle: {
                fontSize: 12,
                fontFamily: 'PingFang SC Regular, PingFang SC Regular-Regular',
                color: '#403970',
              },
              interval: 0,
            },
            axisLine: {
              lineStyle: {
                color: '#2C5F9E',
                width: 1, // 这里是为了突出显示加上的
              },
            },
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle: {
              // 使用深浅的间隔色
              // color: ['#2C5F9E']
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                fontFamily: 'PingFang SC Regular, PingFang SC Regular-Regular',
                color: '#403970',
              },
              interval: 0,
            },
            axisLine: {
              lineStyle: {
                color: '#2C5F9E',
                width: 1, // 这里是为了突出显示加上的
              },
            },
          },

          grid: {
            top: '15%',
            left: '4%',
            right: '4%',
            bottom: '10%',
            containLabel: true,
          }, // 图表位置
          dataZoom: {
            type: 'slider',
            show: true,
            xAxisIndex: [0],
            handleSize: 10, // 滑动条的 左右2个滑动条的大小
            left: 0, // 左边的距离
            right: 0, // 右边的距离
            bottom: 0, // 下边的距离
            realtime: true, // 拖动滚动条时是否动态的更新图表数据
            height: 12, // 滚动条高度
            startValue: 0, // 滚动条开始位置（共100等份）
            endValue: 4,
            labelFormatter: '',
          },
          series: [{
            data: [20, 40, 30, 50, 60, 70, 10],
            type: 'line',
            smooth: true,
            areaStyle: {},
            itemStyle: {
              normal: {
                label: {
                  show: true, // 开启显示
                  position: 'top', // 在上方显示
                  textStyle: {
                    // 数值样式
                    color: '#9C7EFC',
                    fontSize: 12,
                  },
                },
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                                               offset: 0,
                                                                               color: '#757AF2',
                                                                             }, // 柱图渐变色
                                                                             {
                                                                               offset: 1,
                                                                               color: '#C5BCFF',
                                                                             }, // 柱图渐变色
                ]),
              },
            },
          }],
        }

        option && charts1.setOption(option)
      },
      drawPie2 () {
        const ref = this.$refs.chart2
        var charts2 = this.$echarts.init(ref)
        var option
        option = {
          title: {
            text: '日签署协议',
            left: '0',
            top: '0',
            textStyle: {
              fontSize: 18,
              fontFamily: ' Microsoft YaHei, Microsoft YaHei-Bold',
              color: '#9C7EFC',
            },
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月'],

            axisLabel: {
              textStyle: {
                fontSize: 12,
                fontFamily: 'PingFang SC Regular, PingFang SC Regular-Regular',
                color: '#403970',
              },
              interval: 0,
            },
            axisLine: {
              lineStyle: {
                color: '#2C5F9E',
                width: 1, // 这里是为了突出显示加上的
              },
            },
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle: {
              // 使用深浅的间隔色
              // color: ['#2C5F9E']
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                fontFamily: 'PingFang SC Regular, PingFang SC Regular-Regular',
                color: '#403970',
              },
              interval: 0,
            },
            axisLine: {
              lineStyle: {
                color: '#2C5F9E',
                width: 1, // 这里是为了突出显示加上的
              },
            },
          },

          grid: {
            top: '15%',
            left: '4%',
            right: '4%',
            bottom: '10%',
            containLabel: true,
          }, // 图表位置
          dataZoom: {
            type: 'slider',
            show: true,
            xAxisIndex: [0],
            handleSize: 10, // 滑动条的 左右2个滑动条的大小
            left: 0, // 左边的距离
            right: 0, // 右边的距离
            bottom: 0, // 下边的距离
            realtime: true, // 拖动滚动条时是否动态的更新图表数据
            height: 12, // 滚动条高度
            startValue: 0, // 滚动条开始位置（共100等份）
            endValue: 4,
            labelFormatter: '',
          },
          series: [{
            data: [20, 40, 30, 50, 60, 70, 10],
            type: 'line',
            smooth: true,
            areaStyle: {},
            itemStyle: {
              normal: {
                label: {
                  show: true, // 开启显示
                  position: 'top', // 在上方显示
                  textStyle: {
                    // 数值样式
                    color: '#9C7EFC',
                    fontSize: 12,
                  },
                },
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                                               offset: 0,
                                                                               color: '#757AF2',
                                                                             }, // 柱图渐变色
                                                                             {
                                                                               offset: 1,
                                                                               color: '#C5BCFF',
                                                                             }, // 柱图渐变色
                ]),
              },
            },
          }],
        }

        option && charts2.setOption(option)
      },
      drawPie4 () {
        const ref = this.$refs.chart4
        if (!ref) {
          return
        }
        const charts4 = this.$echarts.init(ref)
        const option = {
          title: {
            text: '负荷状态占比',
            left: '0',
            top: '0',
            textStyle: {
              fontSize: 18,
              fontFamily: ' Microsoft YaHei, Microsoft YaHei-Bold',
              color: '#2AAE78',
            },
          },
          left: 'center',
          tooltip: {
            trigger: 'item',
          },
          legend: {
            bottom: '0',
            left: 'left',
          },
          color: ['#20A093', '#F5B925', '#E7461A'],
          series: [
            {
              type: 'pie',
              left: 'center',
              width: '190%',
              radius: '50%',
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1,
              },
              emphasis: {
                label: {
                  show: true,
                },
              },
              label: {
                alignTo: 'edge',
                formatter: '{name|{b}}\n{time|{c} 个}',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                  time: {
                    fontSize: 12,
                    color: '#999',
                  },
                },
              },
              labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80,
              },
              data: [{ name: '充足', value: 100 },
                     { name: '一般', value: 80 },
                     { name: '紧张', value: 50 }],
            },
          ],
        }
        charts4.setOption(option)
        charts4.on('click', function (params) {
          console.log(params)
          // that.$router.push({
          //   name: 'ProblemList',
          //   params: {
          //     problemStatus: params.data.status,
          //     _createdFrom: that.$moment().week(that.$moment().week()).startOf('week').format('YYYY-MM-DD'),
          //     _createdTo: that.$moment().week(that.$moment().week()).endOf('week').format('YYYY-MM-DD'),
          //   },
          // })
        })
      },
      drawPie3 () {
        const ref = this.$refs.chart3
        if (!ref) {
          return
        }
        const charts3 = this.$echarts.init(ref)
        var emphasisStyle = {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)',
          },
        }
        const option = {
          title: {
            text: '各停车场收益对比',
            left: '0',
            top: '0',
            textStyle: {
              fontSize: 18,
              fontFamily: ' Microsoft YaHei, Microsoft YaHei-Bold',
              color: '#0072DC',
            },
          },
          legend: {
            data: ['完成个数'],
            left: '10%',
            top: '',
          },
          dataZoom: {
            type: 'slider',
            show: true,
            xAxisIndex: [0],
            handleSize: 10, // 滑动条的 左右2个滑动条的大小
            left: 0, // 左边的距离
            right: 0, // 右边的距离
            bottom: 0, // 下边的距离
            handleColor: 'rgb(153, 152, 152)', // h滑动图标的颜色
            backgroundColor: '#E2E2E2',
            realtime: true, // 拖动滚动条时是否动态的更新图表数据
            height: 12, // 滚动条高度
            startValue: 0, // 滚动条开始位置（共100等份）
            endValue: 12,
          },
          tooltip: { trigger: 'axis' },
          xAxis: {
            data: ['汉峪金谷停车场', '铭盛大厦', '软件园', '盛福'],
            name: '停车场名称',
            type: 'category',
            axisPointer: {
              type: 'shadow',
            },
            nameTextStyle: {
              padding: 20,
            },
            axisLabel: {
              // rotate: 90,
              interval: 0,
              // rotate: 45, //代表逆时针旋转45度
              // x轴字数限制
              formatter: function (params) {
                var newParamsName = ''
                var paramsNameNumber = params.length
                var provideNumber = 4
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''
                    var start = p * provideNumber
                    var end = start + provideNumber
                    if (p === rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      if (p > 2) {
                        tempStr = '...'
                        newParamsName += tempStr
                        break
                      } else {
                        tempStr = params.substring(start, end)
                        if (p < 2) {
                          tempStr += '\n'
                        }
                      }
                    }
                    newParamsName += tempStr
                  }
                } else {
                  newParamsName = params
                }
                return newParamsName
              },
            },
          },
          yAxis: [
            {
              type: 'value',
              name: '',
              min: 0,
              max: 30,
              interval: 5,
              axisLabel: {
                formatter: '{value}',
              },
            },

          ],
          grid: {
            bottom: 45,
          },
          series: [
            {
              name: '收益',
              barWidth: 40,
              type: 'bar',
              color: '#0072DC',
              emphasis: emphasisStyle,
              data: [1, 5, 6, 10],
              label: {
                show: true,
                position: 'top',
              },
            },
          ],
        }
        charts3.setOption(option)
      },

    },
  }
</script>
<style scoped lang ='scss'>
</style>
